/* eslint-disable */
import * as Sentry from '@sentry/browser';

var apiCaller = require('../apiCaller');
var currentUser = require('../currentUser');
let APP = require('../config');

const LoginView = Backbone.Marionette.ItemView.extend({
  template: require('./templates/login.pug'),

  ui: {
    $emailInput: '[name=email]',
    $passwordInput: '[name=password]',
    $errorContainer: '.alert-danger',
    $btnSubmit: '[type=submit]',
  },

  behaviors: {
    DisplayMessages: {},
    DisplayLoader: {},
  },

  events: {
    'submit form': 'login',
  },

  onRender() {
    $('html > body').attr('data-view', 'not-authenticated');
  },

  onDomRefresh() {
    this.showCookieWarning();
  },

  showCookieWarning() {
    if (!navigator.cookieEnabled) {
      this.triggerMethod(
        'ShowError',
        {
          message: `Oops! It doesn't look like you have cookies enabled. You'll need them in order to continue.`,
        },
        60 * 1000 * 1000,
      );
    }
  },

  async login(e) {
    e.preventDefault();
    this.triggerMethod('ShowLoader');

    try {
      const user = await apiCaller.tryLogin(
        this.ui.$emailInput.val(),
        this.ui.$passwordInput.val(),
      );

      // feature flags come with page setup vars and are only accurate on a per user basis
      // once the user is defined (=logged in). If we stayed in the backbone app after logging in,
      // we'd have to refetch the page setup vars here. However, we are forwarding to the vortex app,
      // which refetches page setup vars by itself

      currentUser.set(user);
      // If both user id and email are set, only id will be visible in Sentry issue
      Sentry.setUser({
        email: user.email,
      });

      let router = require('../router');
      if (!user.isRecoveryQuestionSet) {
        // After switching from Stormpath to Okta, we need users to create a security question and answer
        router.goToSetRecoveryQuestion();
      } else {
        APP.recoveryQuestionSet = true;
        router.goToRootUrl();
      }
    } catch (error) {
      this.triggerMethod('ResetLoader');
      this.triggerMethod('ShowError', error, 12000);
    }
  },
});

module.exports = LoginView;
